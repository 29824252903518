import React, { Component } from 'react';
import MyAccount from './myaccount';
import MyWatchlist from './mywatchlist';
import MyProfile from './myprofile';
import MyAuctions from './myauctions';
import MyOrders from './myorders';
import MyTickets from './mytickets';
import MySales from './mysales';
import MyQuestions from './myquestions';

export class UserContent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    errorDesc:'',
    isOpen: false,
    activeModal: null,
    err: false,
    pageTitle: '',
  }
  

  
  
  componentDidMount() {
   
  }

  render() {
    switch(this.props.activetab) {
      case 'hesapbilgilerim':
        return <MyAccount  activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />
      case 'profilim':
        return <MyProfile  activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />        
      case 'mezatlarim':
        return <MyAuctions activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />        
      case 'destektaleplerim':
        return <MyTickets activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />        
      case 'siparislerim':
        return <MyOrders activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />        
      case 'sorularim':
        return <MyQuestions activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />        
      case 'satislarim':
        return <MySales activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />        
      default:
        return <MyWatchlist  activetab={this.props.activetab} q={this.props.q} apiurl={this.props.apiurl}  slug={this.props.slug} />
    }
  }

}


export default UserContent;