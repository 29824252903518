import React, { Component } from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import UserMobileMenu from './usermobilemenu';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';


export class MyProfile extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    errorDesc:'',
    isLoading: false,
    isAuthenticated: false,
    isOpen: false,
    activeModal: null,
    err: false,
    myNotifications: [],
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, activeModal: null });
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  componentDidMount() {
    this.setState({isLoading:true, isAuthenticated: false});
    const { cookies } = this.props;

    fetch(this.props.apiurl + '/api/myprofile', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        },
        credentials: 'include'
      })
      .then((response) => {
        if(!response.ok) {
            return response.json();
          }
          else{
            return response.json();
          } 
      })
      .then((responseData) => {
        if (responseData.message==='Unauthenticated.') {
          this.setState({ isLoading: false, isAuthenticated:false })
        } else {
          if (responseData) {
            this.setState({ 
               isLoading: false, 
               isAuthenticated:true, 
               myNotifications: responseData.notifications})
          }
        }
       })

      .catch((error) => {
        this.setState({errorDesc: error});
      });
  }

  render() {
    document.title = "Mezatvar - Profilim"
    if (this.state.isAuthenticated === true) {
      return (
        !this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          

            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">

              <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Bildirimlerim</li>
                </ol>
              </nav>

              <div className="row gutters-sm">
                <div className="col-md-4 d-none d-md-block">
                  <UserLeftMenu activetab={this.props.activetab}/>
                </div>
                <div className="col-md-8">
                  <div className="card">
                  <UserMobileMenu activetab={this.props.activetab}/>
                    <div className="card-body tab-content">
                      <div className="tab-pane active" id="profile">
                        <h6>Bildirimlerim</h6>
                        <hr/>
                        <div className="row">
                        
        </div>

                        <hr />
                        {/*<h6>Bildirimlerim</h6>*/}
                        <hr />
                        {this.state.myNotifications.length>0 && this.state.myNotifications.map((mNotif) => (
                        <React.Fragment>
                        <a href={mNotif.notification_target_link}>
                          <div className="alert alert-warning border-warning mb-4" role="alert">
                          <div className="font-weight-bold">{mNotif.notification_title}</div>
                          <div><p className="pb-2">{mNotif.notification_message}</p>
                              <p className="float-right text-primary font-italic p-2">
                                {this.formatDate(mNotif.created_at)}
                              </p>
                          </div>
                          </div>

                        </a>
                        </React.Fragment>
                        ))
                        }

                      </div>              
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    } else {
      return (!this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          <NotAuthorized />
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    }

  }

}


export default withCookies(MyProfile);