import React, { Component } from 'react';
export class UserLeftMenu extends Component {

    render() {
        return (
                <div className="card">
                    <div className="card-body">
                      <nav className="nav flex-column nav-pills">
                       <a href="/u/profilim" data-toggle="tab" className={this.props.activetab=="profilim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user mr-2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>Bildirimlerim
                        </a>
                        <a href="/u/hesapbilgilerim" data-toggle="tab" className={this.props.activetab=="hesapbilgilerim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> Hesap Bilgilerim
                        </a>

                        <a href="/u/mezatlarim" data-toggle="tab" className={this.props.activetab=="mezatlarim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg> Mezatlarım
                        </a>
                        <a href="/u/takiplistem" data-toggle="tab" className={this.props.activetab=="takiplistem" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> Takip Listem
                        </a>
                        <a href="/u/siparislerim" data-toggle="tab" className={this.props.activetab=="siparislerim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg> Sipariş Takibi
                        </a>
                        <a href="/u/satislarim" data-toggle="tab" className={this.props.activetab=="satislarim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg> Satışlarım
                        </a>
                        <a href="/u/destektaleplerim" data-toggle="tab" className={this.props.activetab=="destektaleplerim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg> Destek Taleplerim
                        </a>
                        <a href="/u/sorularim" data-toggle="tab" className={this.props.activetab=="sorularim" ?  "nav-item nav-link has-icon nav-link-faded active" : "nav-item nav-link has-icon nav-link-faded bg-light my-1"}>
                        
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg> Sorularım 
                        </a>
                        <a href="/cikis" data-toggle="tab" className="nav-item nav-link has-icon nav-link-faded bg-light my-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M10 22H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5"></path><polyline points="17 16 21 12 17 8"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> Çıkış Yap
                        </a>
                      </nav>
                    </div>
                </div>
        )
    }
}

export default UserLeftMenu;