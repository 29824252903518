import React, {Component} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import TopNav from './topnav';
import SearchBox from '../../components/search/searchbox';
//import FastCart from './fastcart';
import UserDropdown from '../../components/userdropdown';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Headroom from 'react-headroom';
import Banner from '../../parts/header/banner';
import swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

import '../../assets/css/usernotifications.css';
import '../../assets/css/sidebar.css';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.handleOpenNotifications = this.handleOpenNotifications.bind(this);
    this.handleCloseNotifications = this.handleCloseNotifications.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.state = {
        url: this.props.apiurl + '/api/categories',
        authCheckUrl: this.props.apiurl + '/api/user',
        isLoaded: false,
        isLoggedIn: false,
        err: false,
        errDetails: '',
        userid: null,
        cartItemCount: 0,
        notificationCount: 0,
        userNotifications: [],
        host: null,
        open: false,
        isNotificationsLoaded: false,
        sidebarClass: 'd-none',
        kategoriler: [],
      }
  }
  handleCloseNotifications () {
    this.setState({open: false});
  }
  handleOpenNotifications = (e) => {
    var stOpen  = this.state.open;
    const { cookies } = this.props;
    this.setState ({open : !stOpen, isNotificationsLoaded:false, notificationCount:0});
    fetch(this.props.apiurl + '/api/mynotifications' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookies.get('access-token') ,
      },
      credentials: 'include'
    })
    .then((response) => {
      if(!response.ok) {
        throw new Error(response.statusText)
      }
      else{
        return response.json();
      } 
    })
    .then((responseData) => {
        this.setState({userNotifications: responseData.notifications, isNotificationsLoaded: true});
       
      })

    .catch((error) => {
      this.setState({err:true, isNotificationsLoaded: true});
    });
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  openNav() {
    this.setState({sidebarClass: 'sidebar'});
  }
  
  /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
  closeNav() {
    this.setState({sidebarClass: 'd-none'});
  }

  getCategories() {
    fetch(this.state.url, {method: 'GET', credentials: 'include'})
      .then(res => res.json())
      .then((data) => {
        this.setState({ kategoriler: data })
      })
      .catch(err => {
          // window.location = "/maintenance";
      });  

  }
  componentDidMount() {
    const { cookies } = this.props;
   this.setState({isLoaded: false});  
    this.getCategories();
    fetch(this.state.authCheckUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        },
        credentials: 'include'
      })
      .then((response) => {
        if(!response.ok) {
          throw new Error(response.statusText)
        }
        else{
          return response.json();
        } 
      })
      .then((responseData) => {
         if (responseData.message==='Unauthenticated.') {
             this.setState({isLoggedIn:false, isLoaded: true, userid: null, cartItemCount: 0});
         } else {
           if (this.props.slug=='girisyap' || this.props.slug=='kayitol') {
            window.location = "/u/profilim";
           }
            this.setState({isLoggedIn:true, isLoaded: true, userid: responseData.user.id, host: responseData.host, cartItemCount: responseData.cartItemCount, notificationCount: responseData.notificationCount});
            window.Echo.private('App.Models.User.' + responseData.user.id)
            .listen('NewUserNotification',(e) => {
                  let notificationCount = this.state.notificationCount;
                  this.setState({notificationCount: notificationCount+1}); 
            })
            .listen('ItemAddedToCart',(e) => {
              switch (this.props.slug) {
                case 'sepetim':
                  window.location.reload();
                  break;
                default:
                  let cartCount = this.state.cartItemCount+1;
                  this.setState({cartItemCount: cartCount});              
                  swal.fire({
                    icon: 'info',
                    text: 'Sepetinize yeni bir ürün eklendi',
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });    
              }
                        
            })
         }
        })

      .catch((error) => {
        this.setState({err:true, errDetails: error, isLoaded:true, isLoggedIn:false});
      });
  }
  
    render(){
        return(
            <React.Fragment>
               <Banner placement='5' slug={this.props.slug} apiurl={this.props.apiurl} />
                      <div id="snackbar" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-body" id="snackbar-content">
                        </div>
                      </div>
            <Headroom>
              <div className="col-sm-12 col-md-12 bg-header shadow-lg d-flex m-0 p-0 d-print-none">
                <div className="col-sm-8 col-md-3 d-flex mx-auto my-auto">
                  <div id="main" className="d-md-none d-sm-flex pr-2 pb-2 pt-2 pl-1 m-0">
                    <button className="btn btn-dark" onClick={this.openNav}>&#9776; </button>
                  </div>

                  <a className="mx-auto my-auto" href="/">
                    <picture>
                      <source media="(min-width:650px)" srcSet="./assets/images/logo.png" />
                      <source media="(min-width:320px)" srcSet="./assets/images/logo_sm.png" />
                      <img src="./assets/images/logo.png" className='img-fluid' alt="Mezat Var" />
                    </picture>
                  </a>
                </div>
                <div className="col-md-6 d-none d-md-block my-auto mx-auto" id='toplinks'>
                    <div><a href="/gunun_mezatlari">Günün Mezatları</a> | 
                    <a href="/gelecek_mezatlar">Gelecek Mezatlar</a> | 
                    <a href="/u/siparislerim">Sipariş Takibi</a> | 
                    <a href="/destekmerkezi">Destek Merkezi</a> | 
                    <a href="/satisyap" className="dikkatcek">Satış Yap</a> 
                    </div>
                    <SearchBox q={this.props.q} apiurl={this.props.apiurl}></SearchBox>
                </div>
                {this.state.isLoaded && this.state.isLoggedIn &&
                <div className="col-sm-4 col-md-3 mx-auto my-auto d-flex text-right ">
                    <div>
                      <UserDropdown apiurl={this.props.apiurl}/>
                    </div>
                    <div>
                        <a className="btn btn-light btn-sm m-1 mezatfont" href="/sepetim">
                          <div className="position-relative">                 
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg><span className="d-none d-md-inline"> Sepetim</span> 
                          {this.state.cartItemCount>0 && <span className="badge position-absolute top-0 start-0 translate-middle badge-pill badge-success">{this.state.cartItemCount}</span>}
                          </div>   
                        </a>
                    </div>
                    <div>
                      <button className="btn btn-light btn-sm m-1" onClick={e => this.handleOpenNotifications(e)}>
                        <div className="position-relative"> 
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                        
                            {this.state.notificationCount>0 && <span className="badge position-absolute top-0 start-0 translate-middle badge-pill badge-danger">{this.state.notificationCount}</span>}
                            {this.state.open && 
                            
                              <div className="notcontainer text-left dropdown-menu dropdown-menu-right" >
                                <h6 className="dropdown-header">Bildirimler</h6>
                                      {this.state.userNotifications.length>0 && this.state.userNotifications.map((usernot) => (
                                        <div>
                                        <a className="dropdown-item  text-wrap" href={usernot.notification_target_link} title={usernot.notification_message}>{usernot.notification_message}</a>
                                        <div className="dropdown-divider"></div>
                                        </div>
                                      )
                                      )}
                                      {this.state.userNotifications.length==0 && 
                                      <React.Fragment>
                                        {this.state.isNotificationsLoaded===true && <span className="row text-center text-break p-3">Henüz bir bildiriminiz bulunmamaktadır</span>}
                                        {this.state.isNotificationsLoaded===false && <span className="row d-flex justify-content-center"><Spinner animation="border" variant="primary"/></span>}
                                      </React.Fragment>
                                      }
                                      <div className="row justify-content-center">
                                        <a className="btn btn-light btn-sm" href="/u/profilim#bildirimler">Tüm Bildirimleri Oku</a>
                                      </div>
                                    </div>
                            }
                        </div>
                      </button>
                    </div>
                    
                  </div>
                    
                }
                {this.state.isLoaded && !this.state.isLoggedIn &&
                <div className="col-sm-5 col-md-3 my-auto mx-auto d-flex text-right justify-content-center">
                        <a className="btn btn-sm btn-light m-1 d-md-none" href="/kayitol">
                            Üye Ol 
                        </a>

                        <a className="btn btn-sm btn-light m-1 d-md-none" href="/girisyap">              
                            Giriş Yap
                        </a>

                        <a className="btn btn-light m-1 d-none d-md-block" href="/kayitol">
                            Üye Ol 
                        </a>

                        <a className="btn btn-light m-1 d-none d-md-block" href="/girisyap">              
                            Giriş Yap
                        </a>
                </div>
                }
              </div>
                  <div className='navborder d-none d-md-block bg-secondary'></div>
                  <div className="col-md-12  d-none d-md-block justify-content-center">
                     {this.state.isLoaded && this.state.kategoriler && <TopNav veri={this.state.kategoriler}></TopNav>}
                  </div>
            
            </Headroom>

            <div id="mySidebar" className={this.state.sidebarClass}>
              <a href="#" className="closebtn" onClick={this.closeNav}>&times;</a>
              <ul className="text-left">
              <li><a href="/">Anasayfa</a></li>
              {this.state.isLoaded && !this.state.isLoggedIn && <li><a href="/kayitol">Üye Ol</a></li>}
              {this.state.isLoaded && !this.state.isLoggedIn && <li><a href="/girisyap">Giriş Yap</a></li>}
              {this.state.isLoaded && !this.state.isLoggedIn && <div className="dropdown-divider"></div>}
              <li><a href="/gunun_mezatlari">Günün Mezatları</a></li>
              <li><a href="/gelecek_mezatlar">Gelecek Mezatlar</a></li>
              <li><a href="/u/siparislerim">Sipariş Takibi</a></li>
              <li><a href="/destekmerkezi">Destek Merkezi</a></li>
              <li><a href="/satisyap" className="dikkatcek">Satış Yap</a></li>
              {this.state.kategoriler.filter(category => category.parent === 0).map(kat => 
                  <li key={kat.id}><a href={'/kategori/' + kat.slug}>{kat.title}</a></li>)
              }
              </ul>
            </div>

          </React.Fragment> 
        )
      }

    }

export default withCookies(Header);